import React, {Component, Suspense} from 'react';
import Drawer from '@material-ui/core/Drawer';
import {isMobile} from "@/Helpers/Function";
import Svg from "@/Pages/All/Svg";

class PWA extends Component {
    state = {
        open: true
    };

    closeModalWidget = () => {
        this.setState({open: false})
    };

    render() {
        // Detects if device is on iOS
        const isIos = () => {
            return [
                    'iPhone Simulator',
                    'iPhone'
                ].includes(navigator.platform)
                // iPad on iOS 13 detection
                || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }

        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
        const {open} = this.state;

        const ShareIphone = () => (
            <Svg id={`shareIphone`}
                 className={`shareIphone`}
                 style={{
                     width: 24,
                     height: 25,
                     paddingLeft: 4,
                     paddingRight: 4
                 }}
            />
        );

        // Checks if should display install popup notification:
        if (
            isIos() &&
            isMobile() &&
            !isInStandaloneMode() &&
            !localStorage.getItem('PWA')
        ) {
            localStorage.setItem('PWA', '1');

            return (
                <Drawer id={`drawer`}
                        className={`pwa`}
                        anchor={`bottom`}
                        open={open}
                        onClose={() => this.closeModalWidget()}
                >
                    <div className={`modal__header`}>
                        <div className={`dagger-box`} onClick={() => this.closeModalWidget()}>
                            <Svg id={`dagger-close`} className={`svg__dagger-close`} />
                        </div>
                        <p className={`modal-title`}
                           style={{
                               lineHeight: 1.5
                           }}>
                            Добавить приложение PLATINA на главный экран:
                        </p>
                    </div>
                    <div className={`modal__body`}
                         style={{
                             display: 'flex',
                             flexDirection: 'row',
                             flexWrap: 'wrap',
                             alignItems: 'center',
                             textAlign: 'center',
                             fontSize: '17px',
                             lineHeight: 1.5,
                             padding: '10px, 0'
                         }}>
                        Нажмите
                        <ShareIphone />
                        , затем На экран "Домой"
                    </div>
                </Drawer>
            )
        }

        return null;
    }
}

export default PWA;
